import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const Footer = () => {
  return (
    <footer className='mb-5'>
      <div className='container'>
        <div className='row justify-content-center my-5'>
          <div className='col-auto'>
            <StaticImage
              className='card-img-top'
              src={'../images/icon.png'}
              alt={'Hum Sath Sewa Leicester Logo'}
              placeholder='blurred'
              Layout={'fixed'}
              formats={['auto', 'webp', 'avif']}
              width={250}
              height={250}
              quality={100}
            ></StaticImage>
          </div>
        </div>
      </div>
      <div className='small text-center text-dark'>
        Copyright © 2015 - {new Date().getFullYear()}
      </div>
    </footer>
  )
}

export default Footer
