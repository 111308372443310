import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Seo = ({ pageTitle }) => {
  const query = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          description
          siteUrl
          title
        }
      }
    }
  `)

  const siteMetadata = query.site.siteMetadata
  const metaDescription =
    pageTitle === undefined ? siteMetadata.description : pageTitle
  const metaTitle = `${siteMetadata.title} | ${pageTitle}`

  const metaImage = `${siteMetadata.siteUrl}/icon.png`

  return (
    <div className='application'>
      <Helmet>
        <html lang='en-GB'></html>
        <title>{metaTitle}</title>
        <meta charSet='utf-8' />
        <meta name='description' content={metaDescription} />
        <meta name='author' content={siteMetadata.title} />
        <meta name='robots' content='index,follow' />
        <meta name='language' content='en-GB' />
        <meta name='copyright' content={siteMetadata.title} />
        <meta name='Classification' content='Business' />
        <meta name='reply-to' content='info@humsathsewaleicester.co.uk' />

        {/* OpenGraph */}
        <meta property='og:title' content={siteMetadata.title} />
        <meta property='og:type' content='website' />
        <meta name='og:url' property={siteMetadata.siteUrl} />
        <meta property='og:image' content={metaImage} />
        <meta property='og:site_name' content={siteMetadata.title} />
        <meta property='og:description' content={metaDescription} />
        <meta property='og:email' content='info@whumsathsewaleicester.co.uk' />
        <meta property='og:locality' content='Leicester' />
        <meta property='og:region' content='England' />
        <meta property='og:country-name' content='United Kingdom' />

        {/* OpenGraph Twitter */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta property='twitter:domain' content={siteMetadata.siteUrl} />
        <meta property='twitter:url' content={siteMetadata.siteUrl} />
        <meta name='twitter:title' content={metaTitle} />
        <meta name='twitter:description' content={metaDescription} />
        <meta name='twitter:image' content={siteMetadata.siteUrl} />
      </Helmet>
    </div>
  )
}

export default Seo
