import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const Navbar = () => {
  return (
    <nav className='navbar navbar-expand-lg navbar-light'>
      <div className='container-fluid'>
        <Link to='' className='navbar-brand'>
          <StaticImage
            className='card-img-top'
            src={'../images/icon.png'}
            alt={'Hum Sath Sewa Leicester Logo'}
            placeholder='blurred'
            Layout={'fixed'}
            formats={['auto', 'webp', 'avif']}
            width={50}
            height={50}
            quality={100}
          ></StaticImage>
        </Link>
        <Link to='' className='navbar-brand'>
          Hum Sath Sewa
        </Link>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarNav'
          aria-controls='navbarNav'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse ' id='navbarNav'>
          <ul className='navbar-nav'>
            <li className='nav-item'>
              <Link to='/' className='nav-link'>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/blog' className='nav-link'>
                Blog
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/contact' className='nav-link'>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
